// @mui
import { Box } from '@mui/material';
// next
import NextImage, { ImageProps, StaticImageData } from 'next/image';
// utils
import imageLoader, { imageLoaderBlured } from '@/utils/imageLoader';
// images
import { noImage } from '@/images';

function handleError(event: any) {
  event.target.src = noImage.src;
  event.target.srcset = noImage.src;
}

const getRatio = (ratio = '1/1') => {
  return {
    '4/3': 'calc(100% / 4 * 3)',
    '3/4': 'calc(100% / 3 * 4)',
    '6/4': 'calc(100% / 6 * 4)',
    '4/6': 'calc(100% / 4 * 6)',
    '16/9': 'calc(100% / 16 * 9)',
    '9/16': 'calc(100% / 9 * 16)',
    '21/9': 'calc(100% / 21 * 9)',
    '9/21': 'calc(100% / 9 * 21)',
    '32/5': 'calc(100% / 32 * 5)',
    '48/25': 'calc(100% / 48 * 25)',
    '13/7': 'calc(100% / 13 * 7)',
    '1/1': '100%',
  }[ratio];
};

const Image = ({
  sx,
  ratio,
  onError,
  width,
  height,
  cardClass,
  imageClass,
  alt,
  ...other
}: Props) => {
  if (!other.src) {
    other.src = noImage.src;
  }
  if (ratio) {
    return (
      <Box
        component="span"
        className={cardClass ? cardClass : ''}
        sx={{
          width: 1,
          lineHeight: 0,
          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          pt: getRatio(ratio),
          ...sx,
        }}
      >
        <NextImage
          alt={alt ?? ''}
          loader={imageLoader}
          onError={onError ?? handleError}
          className={imageClass ? imageClass : ''}
          blurDataURL={imageLoaderBlured({ src: other.src, width })}
          fill
          {...other}
        />
      </Box>
    );
  }
  return (
    <Box
      component="span"
      className={cardClass ? cardClass : ''}
      sx={{
        lineHeight: 0,
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        '& .wrapper': {
          width: 1,
          height: 1,
          backgroundSize: 'cover !important',
        },
        ...sx,
      }}
    >
      <NextImage
        loader={imageLoader}
        onError={onError ?? handleError}
        className={imageClass ? imageClass : ''}
        placeholder="blur"
        alt={alt ?? ''}
        blurDataURL={imageLoaderBlured({ src: other.src, width })}
        {...(width && { width })}
        {...(height && { height })}
        {...other}
        fill={!width && !height}
      />
    </Box>
  );
};

// ----------------------------------------------------------------------

type Props = {
  disabledEffect?: boolean;
  effect?: string;
  ratio?:
    | '4/3'
    | '3/4'
    | '6/4'
    | '4/6'
    | '16/9'
    | '9/16'
    | '21/9'
    | '9/21'
    | '13/7'
    | '32/5'
    | '1/1';
  sx?: object;
  onError?: any;
  width?: number;
  height?: number;
  priorty?: string;
  cardClass?: string;
  imageClass?: string;
  src?: string | StaticImageData;
  alt?: string;
} & ImageProps;

export default Image;
