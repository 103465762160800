// @reduxjs
import { createSlice } from '@reduxjs/toolkit';
import { UserGroupState } from './types';
import { STATUS, USER_GROUP_MEMBER_TYPE, USER_GROUP_TYPE } from '@/enums';

// ----------------------------------------------------------------------

const initialState: UserGroupState = {
  bannerPhoto: '',
  id: 0,
  position: '',
  status: STATUS.PASSIVE,
  title: '',
  userGroupMemberType: USER_GROUP_MEMBER_TYPE.TEAM_MEMBER,
  userGroupPlanId: 0,
  userGroupType: USER_GROUP_TYPE.INVESTOR,
};

const slice = createSlice({
  name: 'userGroup',
  initialState,
  reducers: {
    SET_USER_GROUP(state, action) {
      Object.assign(state, action.payload);
    },
    RESET_USER_GROUP(state) {
      Object.assign(state, initialState);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const userGroupActions = slice.actions;
