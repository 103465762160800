// axios
import axios from 'axios';
// cookies-next
import { deleteCookie, getCookie } from 'cookies-next';
// react-toastify
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------
const errorToast = (success?: boolean, errorMessage?: string) => {
  if (success === false && errorMessage && typeof window !== 'undefined') {
    toast.error(errorMessage);
  }
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || '',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((request) => {
  if (!request.headers.Authorization) {
    const accessToken = getCookie('accessToken');
    if (accessToken) request.headers.Authorization = `Bearer ${accessToken}`;
  }
  if (typeof sessionStorage !== 'undefined') {
    const referrerUser = sessionStorage?.getItem('referrer-user');

    if (referrerUser) {
      request.headers['referrer-user'] = referrerUser;
    }
    const referrerCommissionPayer = sessionStorage?.getItem('referrer-commission-payer');

    if (referrerCommissionPayer) {
      request.headers['referrer-commission-payer'] = referrerCommissionPayer;
    }
    const referrerTrafficSource = sessionStorage?.getItem('referrer-traffic-source');

    if (referrerTrafficSource) {
      request.headers['referrer-traffic-source'] = referrerTrafficSource;
    }
    const referrerTrafficMedium = sessionStorage?.getItem('referrer-traffic-medium');

    if (referrerTrafficMedium) {
      request.headers['referrer-traffic-medium'] = referrerTrafficMedium;
    }
  }

  let userGroupId;

  if (typeof document !== 'undefined') {
    userGroupId = Number(getCookie('usergroupid'));
  }

  if (userGroupId && !request.headers.UserGroupId) request.headers.UserGroupId = userGroupId;

  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const { success, errorMessage } = response.data;
    errorToast(success, errorMessage);

    return (response || {}).data || undefined;
  },
  (error) => {
    if (!error.response) return Promise.resolve(error);
    const { status, data = {} } = error.response;
    if (status === 401) {
      deleteCookie('accessToken');
      deleteCookie('userGroupId');
      sessionStorage.clear();
      window.location.href = '/';
    }
    if (status / 200 <= 2) errorToast(data.success, data.errorMessage);

    return Promise.resolve(error.message);
  }
);
export default axiosInstance;
