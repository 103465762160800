// @reduxjs
import { createSlice } from '@reduxjs/toolkit';
import { SharedAction, SharedState } from './types';

// ----------------------------------------------------------------------

const initialState: SharedState = {
  countries: [],
  industryInterests: [],
  smeCategories: [],
  states: [],
  userCategories: [],
  categories: [],
  timezones: [],
  submissionRejectOptions: [],
  hearAboutUsItems: [],
  informationVideos: [],
  reportPages: [],
  reportTopics: [],
  strategicPartners: [],
  exploreSmeCategories: [],
};

const slice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    updateData(state, action: SharedAction) {
      state[action.payload.type] = action.payload.data;
    },
  },
});

// Reducer
export default slice.reducer;

export const sharedActions = slice.actions;
