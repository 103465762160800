'use client';

import { createSlice } from '@reduxjs/toolkit';
import { NotificationState } from './types';
import { DashboardUserGetNotificationsListResponseDto } from '@/services/dashboard/user/UserService/dto';

/**
 * Notification Object
 *
 * @param id: number @autoIncrement
 * @param title: string @required
 * @param icon: string @Iconify
 * @param permanent: boolean
 * @param readAt: Date
 * @param createdAt: Date
 * @param action: number
 * @param actionParams: Object
 * @param userId: number
 * @param user: Object
 * @param senderId: number
 * @param sender: Object
 *
 */

// ----------------------------------------------------------------------

const initialState: NotificationState = {
  lastNotificationPK: 0,
  totalNotificationCount: 0,
  unreadNotificationsCount: 0,
  notifications: [],
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    SET_NOTIFICATION_LIST(state, action) {
      const notificationList: DashboardUserGetNotificationsListResponseDto[] = action.payload;
      notificationList.sort(
        ({ createdAt: a }, { createdAt: b }) => new Date(b).getTime() - new Date(a).getTime()
      );
      state.lastNotificationPK = notificationList[0]?.id ?? 0;
      state.totalNotificationCount = notificationList.length;
      state.unreadNotificationsCount = notificationList.filter(({ readAt }) => !readAt).length;

      state.notifications = notificationList;
    },

    ADD_NOTIFICATION(state, action) {
      const notificationObject = action.payload;
      state.lastNotificationPK++;
      state.totalNotificationCount++;
      state.unreadNotificationsCount = notificationObject.readAt
        ? state.unreadNotificationsCount
        : state.unreadNotificationsCount + 1;

      state.notifications = [
        ...state.notifications,
        {
          id: state.lastNotificationPK + 1,
          ...notificationObject,
          createdAt: notificationObject.createdAt ?? new Date(Date.now()),
        },
      ];
    },

    DELETE_NOTIFICATION(state, action) {
      const id = action.payload;
      const notificationObject = state.notifications.find(
        ({ id: notificationId }) => notificationId === id
      );

      if (!notificationObject) return;

      if (notificationObject.readAt) {
        state.totalNotificationCount--;
        state.notifications = state.notifications.filter(
          ({ id: notificationId }) => notificationId !== id
        );
        return;
      }

      state.notifications = state.notifications.filter(
        ({ id: notificationId }) => notificationId !== id
      );
      state.totalNotificationCount--;
      state.unreadNotificationsCount--;
    },

    READ_NOTIFICATION(state, action) {
      const id = action.payload;
      const notificationObject = state.notifications.find(
        ({ id: notificationId }) => notificationId === id
      );

      if (!notificationObject) return;
      if (notificationObject.permanent) return;

      state.notifications = [
        ...state.notifications.filter(({ id: notificationId }) => notificationId !== id),
        { ...notificationObject, readAt: new Date(Date.now()) },
      ];
      state.unreadNotificationsCount--;
    },

    READ_ALL_NOTIFICATION(state) {
      state.notifications = [
        ...state.notifications.map((notification) => {
          if (notification.permanent) return notification;
          return {
            ...notification,
            readAt: new Date(Date.now()),
          };
        }),
      ];
      state.unreadNotificationsCount--;
    },

    CLEAR_ALL_NOTIFICATION(state) {
      Object.assign(state, initialState);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  READ_NOTIFICATION,
  CLEAR_ALL_NOTIFICATION,
  SET_NOTIFICATION_LIST,
  READ_ALL_NOTIFICATION,
} = slice.actions;
