const imageLoader = ({ src, width = 1000, quality = 75 }) => {
  if (!src) return '';
  if (src.startsWith('http://') || src.startsWith('https://') || src.startsWith('/_next/'))
    return src;
  else
    return (
      process.env.REACT_APP_CDN_ADDRESS +
      `fit-in/${width}x${width}/filters:format(webp)/filters:quality(${quality})/public/` +
      src
    );
};

export const imageLoaderBlured = ({ src, width = 1000 }) => {
  if (!src) return '';
  if (src?.src) return src?.src;
  if (src.startsWith('http://') || src.startsWith('https://') || src.startsWith('/_next/'))
    return src;
  else
    return (
      process.env.REACT_APP_CDN_ADDRESS +
      `fit-in/${width}x${width}/filters:quality(50)/filters:blur(20)/filters:format(webp)/public/` +
      src
    );
};

export default imageLoader;
