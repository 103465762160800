'use client';

import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { ReduxRootState, rootPersistConfig, rootReducer } from './rootReducer';
import { Provider } from 'react-redux';
import { initialState as modalInitialState, modalActions } from './slices/modal';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

const { dispatch, getState } = store;

if (typeof window !== 'undefined') {
  dispatch(modalActions.RESET({ state: modalInitialState(dispatch) }));
}

const useSelector = useAppSelector<ReduxRootState>;

const useDispatch = () => useAppDispatch();

type ReduxProviderProps = {
  children: React.ReactNode;
};

function ReduxProvider({ children }: ReduxProviderProps) {
  return <Provider store={store}>{children}</Provider>;
}

export { store, persistor, dispatch, getState, useSelector, useDispatch, ReduxProvider };
