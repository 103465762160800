// redux
import { combineReducers } from 'redux';
// redux-persist
import { persistReducer } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import _sessionStorage from 'redux-persist/lib/storage/session';
// slices
import notificationReducer, { NotificationState } from './slices/notification';
import sharedReducer, { SharedState } from './slices/shared';
import userGroupReducer from './slices/user-group';
import modalReducer, { ModalState } from './slices/modal';

// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key: string, value: string) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});

const _localStorage =
  typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const rootPersistConfig = {
  key: 'root',
  storage: _localStorage,
  keyPrefix: 'root-',
  whitelist: [],
};

const userGroupPersistConfig = {
  key: 'userGroup',
  storage: _localStorage,
  keyPrefix: 'user-group-',
};

const sharedPersistConfig = {
  key: 'shared',
  storage: _sessionStorage,
  keyPrefix: 'shared-',
};

export type ReduxRootState = {
  modal: ModalState;
  shared: SharedState;
  notification: NotificationState;
  userGroup: ReturnType<typeof userGroupReducer>;
};

const rootReducer = combineReducers({
  modal: modalReducer,
  shared: persistReducer(sharedPersistConfig, sharedReducer),
  notification: notificationReducer,
  userGroup: persistReducer(userGroupPersistConfig, userGroupReducer),
});

export { rootPersistConfig, rootReducer };
