// @reduxjs
import { createSlice } from '@reduxjs/toolkit';
import { USER_GROUP_TYPE } from '@/enums/user-group-type.enum';
import { ModalState } from './types';

export const initialState: (dispatch: any) => ModalState = (dispatch: any) => ({
  login: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_LOGIN(open)),
    props: {
      unclosable: false,
    },
    setProps: (props) => dispatch(modalActions.SET_LOGIN({ props })),
  },
  signup: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_SIGNUP(open)),
  },
  createProfile: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_CREATE_PROFILE(open)),
  },
  viewSubmission: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_VIEW_SUBMISSION({ open })),
    props: {
      userId: 0,
      submissionId: 0,
      userGroupId: 0,
      mutateTable: () => {},
      userGroupType: USER_GROUP_TYPE.INVESTOR,
    },
    setProps: (props) => dispatch(modalActions.SET_VIEW_SUBMISSION({ props })),
  },
  events: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_EVENTS({ open })),
    props: {
      defaultEvent: {},
      eventType: 0,
      eventId: 0,
      userGroupId: 0,
      mutate: () => {},
    },
    setProps: (props) => dispatch(modalActions.SET_EVENTS({ props })),
  },
  cohorts: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_COHORTS({ open })),
    props: {
      cohort: {},
      contentId: null,
      userGroupId: 0,
      mutate: () => {},
    },
    setProps: (props) => dispatch(modalActions.SET_COHORTS({ props })),
  },
  programs: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_PROGRAMS({ open })),
    props: {
      defaultProgram: {},
      programId: 0,
      userGroupId: 0,
      mutate: () => {},
    },
    setProps: (props) => dispatch(modalActions.SET_PROGRAMS({ props })),
  },
  services: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_SERVICES({ open })),
    props: {
      defaultService: {},
      serviceId: 0,
      userGroupId: 0,
      mutate: () => {},
    },
    setProps: (props) => dispatch(modalActions.SET_SERVICES({ props })),
  },
  product: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_PRODUCT({ open })),
    props: {
      defaultProduct: {},
      productId: 0,
      userGroupId: 0,
      mutate: () => {},
    },
    setProps: (props) => dispatch(modalActions.SET_PRODUCT({ props })),
  },
  calendlyScheduleMeeting: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_CALENDLY_SCHEDULE_MEETING({ open })),
    props: {
      calendlyLink: null,
      onSuccess: () => {},
      registered: false,
    },
    setProps: (props) => dispatch(modalActions.SET_CALENDLY_SCHEDULE_MEETING({ props })),
  },
  registeredUsers: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_REGISTERED_USERS({ open })),
    props: {
      userGroupId: 0,
      contentId: 0,
      contentType: 0,
      userGroupType: 0,
    },
    setProps: (props) => dispatch(modalActions.SET_REGISTERED_USERS({ props })),
  },
  affiliateUsers: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_AFFILIATE_USERS({ open })),
    props: {
      userGroupId: 0,
      contentId: 0,
      contentType: 0,
    },
    setProps: (props) => dispatch(modalActions.SET_AFFILIATE_USERS({ props })),
  },
  startupViewSubmission: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_STARTUP_VIEW_SUBMISSION({ open })),
    props: {
      investorId: 0,
      userGroupId: 0,
    },
    setProps: (props) => dispatch(modalActions.SET_STARTUP_VIEW_SUBMISSION({ props })),
  },
  createInvestor: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_CREATE_INVESTOR({ open })),
    props: {
      unclosable: false,
    },
    setProps: (props) => dispatch(modalActions.SET_CREATE_INVESTOR({ props })),
  },
  createIncubator: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_CREATE_INCUBATOR({ open })),
    props: {
      unclosable: false,
    },
    setProps: (props) => dispatch(modalActions.SET_CREATE_INCUBATOR({ props })),
  },
  createSME: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_CREATE_SME({ open })),
    props: {
      unclosable: false,
    },
    setProps: (props) => dispatch(modalActions.SET_CREATE_SME({ props })),
  },
  createInstitution: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_CREATE_INSTITUTION({ open })),
    props: {
      unclosable: false,
    },
    setProps: (props) => dispatch(modalActions.SET_CREATE_INSTITUTION({ props })),
  },
  addTeamMember: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_ADD_TEAM_MEMBER({ open })),
    props: {
      userGroupId: 0,
      added: 0,
      setAdded: () => {},
    },
    setProps: (props) => dispatch(modalActions.SET_ADD_TEAM_MEMBER({ props })),
  },
  applyToPitch: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_APPLY_TO_PITCH({ open })),
    props: {
      applyToPitchData: [],
      userGroupId: 0,
      contentId: 0,
    },
    setProps: (props) => dispatch(modalActions.SET_APPLY_TO_PITCH({ props })),
  },
  payment: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_PAYMENT({ open })),
    props: {
      secret: '',
      contentId: '',
      isLoading: false,
      onSuccess: () => {},
      onClose: () => {},
      onCancel: () => {},
      paymentType: 0,
    },
    setProps: (props) => dispatch(modalActions.SET_PAYMENT({ props })),
  },
  userGroupPageBuilder: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_USER_GROUP_PAGE_BUILDER({ open })),
    props: {
      userGroupType: USER_GROUP_TYPE.INVESTOR,
      userGroupDetail: {},
      getUserGroupDetail: () => {},
    },
    setProps: (props) => dispatch(modalActions.SET_USER_GROUP_PAGE_BUILDER({ props })),
  },
  selectStartup: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_SELECT_STARTUP({ open })),
    props: {
      startups: [],
      onSelect: () => {},
    },
    setProps: (props) => dispatch(modalActions.SET_SELECT_STARTUP({ props })),
  },
  rate: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_RATE({ open })),
    props: {
      onRate: () => {},
      ratingId: null,
    },
    setProps: (props) => dispatch(modalActions.SET_RATE({ props })),
  },
  createStartup: {
    open: false,
    setOpen: (open) => dispatch(modalActions.SET_CREATE_STARTUP({ open })),
  },
});

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'modal',
  initialState: initialState(() => {}),
  reducers: {
    RESET(state, action) {
      Object.assign(state, action.payload.state);
    },
    SET_LOGIN(state, action) {
      state.login.open = action.payload;
      state.login.props = { ...state.login.props, ...action.payload.props };
    },

    SET_SIGNUP(state, action) {
      state.signup.open = action.payload;
    },

    SET_CREATE_PROFILE: (state, action) => {
      state.createProfile.open = action.payload;
    },
    SET_VIEW_SUBMISSION: (state, action) => {
      state.viewSubmission.open =
        action.payload.open !== undefined ? action.payload.open : state.viewSubmission.open;
      state.viewSubmission.props = { ...state.viewSubmission.props, ...action.payload.props };
    },
    SET_EVENTS: (state, action) => {
      state.events.open =
        action.payload.open !== undefined ? action.payload.open : state.events.open;
      state.events.props = { ...state.events.props, ...action.payload.props };
    },
    SET_COHORTS: (state, action) => {
      state.cohorts.open =
        action.payload.open !== undefined ? action.payload.open : state.cohorts.open;
      state.cohorts.props = { ...state.cohorts.props, ...action.payload.props };
    },
    SET_PROGRAMS: (state, action) => {
      state.programs.open =
        action.payload.open !== undefined ? action.payload.open : state.programs.open;
      state.programs.props = { ...state.programs.props, ...action.payload.props };
    },
    SET_SERVICES: (state, action) => {
      state.services.open =
        action.payload.open !== undefined ? action.payload.open : state.services.open;
      state.services.props = { ...state.services.props, ...action.payload.props };
    },
    SET_PRODUCT: (state, action) => {
      state.product.open =
        action.payload.open !== undefined ? action.payload.open : state.product.open;
      state.product.props = { ...state.product.props, ...action.payload.props };
    },
    SET_PAYMENT: (state, action) => {
      state.payment.open =
        action.payload.open !== undefined ? action.payload.open : state.payment.open;
      state.payment.props = { ...state.payment.props, ...action.payload.props };
    },
    SET_CALENDLY_SCHEDULE_MEETING: (state, action) => {
      state.calendlyScheduleMeeting.open =
        action.payload.open !== undefined
          ? action.payload.open
          : state.calendlyScheduleMeeting.open;
      state.calendlyScheduleMeeting.props = {
        ...state.calendlyScheduleMeeting.props,
        ...action.payload.props,
      };
    },
    SET_REGISTERED_USERS: (state, action) => {
      state.registeredUsers.open =
        action.payload.open !== undefined ? action.payload.open : state.registeredUsers.open;
      state.registeredUsers.props = { ...state.registeredUsers.props, ...action.payload.props };
    },
    SET_AFFILIATE_USERS: (state, action) => {
      state.affiliateUsers.open =
        action.payload.open !== undefined ? action.payload.open : state.affiliateUsers.open;
      state.affiliateUsers.props = { ...state.affiliateUsers.props, ...action.payload.props };
    },
    SET_STARTUP_VIEW_SUBMISSION: (state, action) => {
      state.startupViewSubmission.open =
        action.payload.open !== undefined ? action.payload.open : state.startupViewSubmission.open;
      state.startupViewSubmission.props = {
        ...state.startupViewSubmission.props,
        ...action.payload.props,
      };
    },
    SET_CREATE_INVESTOR: (state, action) => {
      state.createInvestor.open =
        action.payload.open !== undefined ? action.payload.open : state.createInvestor.open;
      state.createInvestor.props = {
        ...state.createInvestor.props,
        ...action.payload.props,
      };
    },
    SET_CREATE_INCUBATOR: (state, action) => {
      state.createIncubator.open =
        action.payload.open !== undefined ? action.payload.open : state.createIncubator.open;
      state.createIncubator.props = {
        ...state.createIncubator.props,
        ...action.payload.props,
      };
    },
    SET_CREATE_SME: (state, action) => {
      state.createSME.open =
        action.payload.open !== undefined ? action.payload.open : state.createSME.open;
      state.createSME.props = {
        ...state.createSME.props,
        ...action.payload.props,
      };
    },
    SET_CREATE_INSTITUTION: (state, action) => {
      state.createInstitution.open =
        action.payload.open !== undefined ? action.payload.open : state.createInstitution.open;
      state.createInstitution.props = {
        ...state.createInstitution.props,
        ...action.payload.props,
      };
    },
    SET_ADD_TEAM_MEMBER: (state, action) => {
      state.addTeamMember.open =
        action.payload.open !== undefined ? action.payload.open : state.addTeamMember.open;
      state.addTeamMember.props = {
        ...state.addTeamMember.props,
        ...action.payload.props,
      };
    },
    SET_APPLY_TO_PITCH: (state, action) => {
      state.applyToPitch.open =
        action.payload.open !== undefined ? action.payload.open : state.applyToPitch.open;
      state.applyToPitch.props = {
        ...state.applyToPitch.props,
        ...action.payload.props,
      };
    },
    SET_USER_GROUP_PAGE_BUILDER: (state, action) => {
      state.userGroupPageBuilder.open =
        action.payload.open !== undefined ? action.payload.open : state.userGroupPageBuilder.open;
      state.userGroupPageBuilder.props = {
        ...state.userGroupPageBuilder.props,
        ...action.payload.props,
      };
    },
    SET_SELECT_STARTUP: (state, action) => {
      state.selectStartup.open =
        action.payload.open !== undefined ? action.payload.open : state.selectStartup.open;
      state.selectStartup.props = {
        ...state.selectStartup.props,
        ...action.payload.props,
      };
    },
    SET_RATE: (state, action) => {
      state.rate.open = action.payload.open !== undefined ? action.payload.open : state.rate.open;
      state.rate.props = {
        ...state.rate.props,
        ...action.payload.props,
      };
    },
    SET_CREATE_STARTUP: (state, action) => {
      state.createStartup.open =
        action.payload.open !== undefined ? action.payload.open : state.createStartup.open;
    },
  },
});

// Reducer
export default slice.reducer;

export const modalActions = slice.actions;
