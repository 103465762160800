// react
import { forwardRef } from 'react';
// next
import NextLink from 'next/link';
// @mui
import { Box } from '@mui/material';
// components
import { Image } from '@/components/common';
// assets
import { scaleLogo } from 'assets/img';

// ----------------------------------------------------------------------

const ScaleupstreamLogo = forwardRef(
  ({ disabledLink = false, sx, width = 80, height = 30 }: Props, ref) => {
    const logo = (
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          cursor: 'pointer',
          ...sx,
        }}
      >
        <Image src={scaleLogo} alt={'ScaleupStream'} width={width} height={height} />
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <NextLink href="/" prefetch={false}>
        {logo}
      </NextLink>
    );
  }
);

type Props = {
  disabledLink?: boolean;
  isCollapse?: boolean;
  sx?: object;
  width?: number;
  height?: number;
};

ScaleupstreamLogo.displayName = 'ScaleupstreamLogo';

export default ScaleupstreamLogo;
