import { AxiosRequestConfig } from 'axios';
import axiosInstance from '../utils/axios';

export type BaseResponseMeta = {
  currentPage: number;
  perPage: number;
  totalPages: number;
  total: number;
};

export type BaseResponse<T> = {
  success: boolean;
  data?: T;
  errorMessage?: string;
  meta?: BaseResponseMeta;
};

type BaseRequestConfig = AxiosRequestConfig;

export class BaseService {
  private baseUrl: string;
  constructor(baseUrl = '') {
    this.baseUrl = this.trimPath(baseUrl);
  }

  get<T = any>(path: string, config?: BaseRequestConfig): Promise<BaseResponse<T>> {
    path = this.trimPath(path);
    return axiosInstance?.get(`${this.baseUrl}/${path}`, config);
  }

  post<T = any, D = any>(
    path: string,
    data?: D,
    config?: BaseRequestConfig
  ): Promise<BaseResponse<T>> {
    path = this.trimPath(path);
    return axiosInstance?.post(`${this.baseUrl}/${path}`, data, config);
  }

  put<T = any, D = any>(
    path: string,
    data?: D,
    config?: BaseRequestConfig
  ): Promise<BaseResponse<T>> {
    path = this.trimPath(path);
    return axiosInstance?.put(`${this.baseUrl}/${path}`, data, config);
  }

  delete<T = any>(path: string): Promise<BaseResponse<T>> {
    path = this.trimPath(path);
    return axiosInstance?.delete(`${this.baseUrl}/${path}`);
  }

  trimPath(path: string): string {
    if (path.startsWith('/')) {
      path = path.substring(1);
    }
    if (path.endsWith('/')) {
      path = path.substring(0, path.length - 1);
    }
    return path;
  }
}

export default new BaseService();
